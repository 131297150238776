<template>
  <v-sheet>
      <v-container>
        <p class="pl-5 mb-0 primary--text font-weight-black text-h4" style="font-family: Nunito, sans-serif !important">Manage Users</p>
        <v-card class="mt-4" flat outlined>
            <v-card-title>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                 <v-select
                 label="View"
                 :items="['Enabled Users', 'All Users']"
                 v-model="staffStatus"
                 ></v-select>
                </v-col>
                <v-col cols="12" sm="3" md="5">
                  <div class="text-right mt-3">
                    <v-btn class="primary" @click="addDialog = true"><v-icon>mdi-add</v-icon> Add User</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-btn v-show="selected.length > 0" class="error" @click="dialog = true">Disable Users</v-btn>
            </v-card-text>
            <v-data-table
                :headers="headers"
                :items="users"
                :loading="dataLoading"
                :search="search"
                loading-text="Loading... Please wait"
                class="elevation-1"
                hide-default-footer
                disable-pagination
            >
            <template v-slot:item.Name="{ item }">
              {{item.Name}}
              <img src="../assets/telegram.png" v-if="item.TelegramID !== 0" width="13px" alt="">
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="my-1">
              <v-btn
              x-small
              color="red"
              dark
              @click="disableUsers(item, 'disable')"
              v-if="item.UserStatus === 1"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  mdi-delete
                </v-icon>
                Disable
              </v-btn>
              <v-btn
              x-small
              color="success"
              dark
              @click="disableUsers(item, 'enable')"
              v-else
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  mdi-check
                </v-icon>
                Enable
              </v-btn>
              <v-btn color="warning" @click="resetPassword(item)" v-if="currentUser.ID != item.ID" x-small>Reset Password</v-btn>
              <v-btn color="info" @click="openEditDialog(item)" x-small><v-icon x-small class="mr-1">mdi-pencil</v-icon> Edit</v-btn>
              </div>
            </template>
            <template v-slot:item.UserStatus="{ item }">
              <v-icon v-if="item.UserStatus === 1" color="success">mdi-account-check</v-icon>
              <v-icon v-else color="error">mdi-account-cancel</v-icon>
            </template>
            </v-data-table>
        </v-card>
        <v-dialog
          v-model="dialog"
          persistent
          max-width="350"
        >
          <v-card>
            <v-card-title class="headline error--text">
              <v-icon color="error" class="mr-3" large>mdi-alert-circle-outline</v-icon>
              Are you sure?
            </v-card-title>
            <v-card-text>Do you really want to disable selected users.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="dialog = false"
              >
                Disagree
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="disableUsers"
              >
                Agree
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
        v-model="resetModal"
        max-width="600px"
        >
          <v-card>
            <v-card-title>User Password Changed</v-card-title>
            <v-card-text>
              <p><span class="font-weight-medium">{{resetedUser.Name}}</span> password has been reset successfully. New password is <span class="info--text font-weight-black">{{resetedUser.OTP}}</span>.<br>Please mail to <span class="font-weight-medium">{{resetedUser.Name}}</span> email <span class="info--text font-weight-bolder">{{resetedUser.Email}}</span>.</p>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="addDialog"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Add User</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                >
                    <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                      label="Name"
                      :rules="[v => !!v || 'Required']"
                      v-model="addUserName"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                      label="Mobile"
                      :rules="[v => !!v || 'Required']"
                      v-model="addUserMobile"
                      type="number"
                      prefix="960"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                      label="EmployeeNumber"
                      :rules="[v => !!v || 'Required']"
                      v-model="addUserEmpNumber"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-autocomplete
                          v-model="addUserGroup"
                          :items="groups"
                          label="Group"
                          item-text="GroupName"
                          item-value="ID"
                          :rules="[v => !!v || 'Required']"
                          return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                      label="Username"
                      :rules="[v => !!v || 'Required']"
                      v-model="addUserUsername"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                      label="Password"
                      :rules="[v => !!v || 'Required']"
                      v-model="addUserPassword"
                      ></v-text-field>
                    </v-col> -->
                    </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="addDialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="submitUser"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="editDialog"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Edit User</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form
                ref="form2"
                v-model="valid"
                lazy-validation
                >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-autocomplete
                        v-model="editUserGroup"
                        :items="groups"
                        label="Group"
                        item-text="GroupName"
                        item-value="ID"
                        :rules="[v => !!v || 'Required']"
                        return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    label="Mobile"
                    :rules="[v => !!v || 'Required']"
                    v-model="editUserMobile"
                    type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="editDialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="editUser"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <v-snackbar
        :timeout="2000"
        :value="snackBar"
        :color="snackColor"
        absolute
        right
        rounded="pill"
        top
      >
        {{snacktext}}
      </v-snackbar>
  </v-sheet>
</template>

<script>
export default {
  name: 'Locations',
  props: ['currentUser'],
  data: () => ({
    singleSelect: false,
    selected: [],
    users: [],
    allUsers: [],
    groups: [],
    dataLoading: true,
    dialog: false,
    search: '',
    headers: [
      { text: 'ID', value: 'ID', sortable: false },
      { text: 'Name', value: 'Name', sortable: false },
      // { text: 'EmployeeNumber', value: 'EmployeeNumber', sortable: false },
      { text: 'Group', value: 'Group.GroupName', sortable: false },
      { text: 'Mobile Number', value: 'MobileNumber', sortable: false },
      { text: 'Username', value: 'Username', sortable: false },
      { text: 'Status', value: 'UserStatus', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    resetedUser: {},
    resetModal: false,
    addDialog: false,
    addUserUsername: null,
    addUserGroup: null,
    addUserPassword: null,
    addUserName: null,
    addUserEmpNumber: null,
    addUserMobile: null,
    snackBar: false,
    snackColor: '',
    snacktext: '',
    valid: true,
    staffStatus: 'Enabled Users',
    editDialog: false,
    editItem: {},
    editUserGroup: null,
    editUserMobile: null
  }),
  created () {
    this.$http.get(this.$url + '/api/group').then(response => {
      this.groups = response.data.data
    })
    this.getUsers()
  },
  watch: {
    staffStatus: function (val) {
      this.dataLoading = true
      if (val === 'Enabled Users') {
        this.users = this.allUsers.filter(item => item.UserStatus === 1)
        this.dataLoading = false
      } else {
        this.users = this.allUsers
        this.dataLoading = false
      }
    }
  },
  methods: {
    getUsers () {
      this.$http.get(this.$url + '/api/user').then(response => {
        this.allUsers = JSON.parse(JSON.stringify(response.data.data))
        this.users = response.data.data.filter(item => item.UserStatus === 1)
        this.dataLoading = false
      })
    },
    disableUsers (item, type) {
      item.UserStatus = type === 'enable' ? 1 : 0
      this.$http.post(this.$url + '/api/user', item).then(response => {
        this.getUsers()
      }, () => {
        // if (err.response.status === 401) {
        //   this.$store.dispatch('logout')
        //   this.$router.push('/login')
        // }
        // throw err
      })
    },
    resetPassword (item) {
      this.$http.post(`${this.$url}/api/resetpassword/${item.ID}`).then(response => {
        this.resetedUser = response.data.data
        this.resetModal = true
      })
    },
    submitUser () {
      if (this.$refs.form.validate()) {
        const postObj = {}
        postObj.EmployeeNumber = this.addUserEmpNumber
        postObj.GroupID = this.addUserGroup.ID
        postObj.Group = this.addUserGroup
        postObj.MobileNumber = '960' + this.addUserMobile.toString()
        postObj.Name = this.addUserName
        // postObj.Password = this.addUserPassword
        postObj.Username = this.addUserUsername
        this.$http.post(this.$url + '/api/user', postObj).then((response) => {
          this.addDialog = false
          this.snackBar = true
          this.snackColor = 'success'
          this.snacktext = 'New Type Posted'
          this.$refs.form.reset()
          this.getUsers()
        }, (err) => {
          if (err.response.status === 401) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
          } else {
            this.snackBar = true
            this.snackColor = 'error'
            this.snacktext = 'Error Occured. Call IT.'
          }
        })
      }
    },
    openEditDialog (item) {
      this.editItem = item
      this.editUserGroup = item.Group
      this.editUserMobile = parseInt(item.MobileNumber)
      this.editDialog = true
    },
    editUser () {
      if (this.$refs.form2.validate()) {
        const postObj = this.editItem
        postObj.Group = this.editUserGroup
        postObj.GroupID = this.editUserGroup.ID
        postObj.MobileNumber = this.editUserMobile.toString()
        this.$http.post(this.$url + '/api/user', postObj).then((response) => {
          this.editDialog = false
          this.snackBar = true
          this.snackColor = 'success'
          this.snacktext = 'User details updated'
          this.$refs.form2.reset()
          this.getUsers()
        }, (err) => {
          if (err.response.status === 401) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
          } else {
            this.snackBar = true
            this.snackColor = 'error'
            this.snacktext = 'Error Occured. Call IT.'
          }
        })
      }
    }
  }
}
</script>
