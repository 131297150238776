<template>
  <div style="height: 100vh; background-color: #ccf3ff;" class="d-flex align-center">
      <v-row>
          <v-col cols="12" sm="5" class="d-flex justify-center justify-sm-end align-center">
              <div>
                    <v-row class="mb-5">
                      <v-col cols="12" class="d-flex justify-center align-center">
                        <v-img
                          src="logo-blue.png"
                          max-height="100"
                          max-width="100"
                          contain
                        ></v-img>
                      </v-col>
                      <!-- <v-col>
                        <p class="fopnt-weight-black text-h4 mb-5" style="font-family: Nunito, sans-serif !important;">Login</p>
                      </v-col> -->
                    </v-row>
                    <p v-if="showAlert" class="error--text">Username or Password Incorrect</p>
                    <v-form
                    v-model="valid"
                    ref="form"
                    >
                      <div v-if="!hasToChange">
                        <v-text-field
                        label="Username"
                        prepend-inner-icon="mdi-account"
                        rounded
                        outlined
                        background-color="white"
                        dense
                        v-model="username"
                        @change="showAlert = false"
                        >
                        </v-text-field>
                        <v-text-field
                        label="Password"
                        type="password"
                        prepend-inner-icon="mdi-lock"
                        background-color="white"
                        rounded
                        outlined
                        dense
                        v-model="password"
                        @keyup.enter="login"
                        @change="showAlert = false"
                        ></v-text-field>
                        <v-divider></v-divider>
                        <v-btn
                        block
                        rounded
                        class="mt-5"
                        color="primary"
                        @click="login">
                        Login
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-text-field
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        label="New Password"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="[v => !!v || 'New Password is required']"
                        @click:append="showPassword = !showPassword"
                        v-model="NewPassword"
                        rounded
                        outlined
                        dense
                        background-color="white"
                        ></v-text-field>
                        <v-text-field
                        :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                        label="Confirm Password"
                        :type="showPassword2 ? 'text' : 'password'"
                        :rules="[v => !!v && v === NewPassword || 'Password Mismatch']"
                        @click:append="showPassword2 = !showPassword2"
                        v-model="ConfirmPassword"
                        rounded
                        outlined
                        dense
                        background-color="white"
                        @keyup.enter="changePassword"
                        ></v-text-field>
                        <v-btn
                        class="mt-4 elevation-0"
                        color="primary"
                        block
                        @click="changePassword"
                        >
                        Change Password
                        </v-btn>
                      </div>
                    </v-form>
              </div>
          </v-col>
          <v-col cols="6" class="justify-center d-none d-sm-flex">
              <lottie-player src="https://assets7.lottiefiles.com/packages/lf20_fssbwzzm.json" background="transparent"  speed="1"  style="max-width: 500px; height: auto;" loop autoplay></lottie-player>
              <!-- <v-img
                src="logo.png"
                max-height="200"
                max-width="450"
                contain
              ></v-img> -->
          </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    username: '',
    password: '',
    showAlert: false,
    hasToChange: false,
    showPassword: false,
    showPassword2: false,
    NewPassword: null,
    ConfirmPassword: null,
    valid: true
  }),
  methods: {
    login () {
      const username = this.username
      const password = this.password
      this.$store.dispatch('login', { username, password })
        .then(() => {
          // this.$router.replace('/')
          this.$http.get(`${this.$url}/api/currentuser`).then((response) => {
            this.$refs.form.resetValidation()
            if (response.data.data.ChangeAtNextLogon) {
              this.$store.commit('toggleActive', false)
              this.hasToChange = true
            } else {
              this.$store.commit('toggleActive', true)
              this.$router.push('/')
            }
          })
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.showAlert = true
          }
        })
    },
    changePassword: function () {
      if (this.$refs.form.validate() && this.NewPassword === this.ConfirmPassword) {
        const obj = {
          OldPassword: this.password,
          NewPassword: this.NewPassword,
          ConfirmPassword: this.ConfirmPassword
        }
        this.$http.post(`${this.$url}/api/changepassword`, obj).then(response => {
          this.$store.dispatch('logout')
            .then(() => {
              this.hasToChange = false
              this.password = ''
              this.$refs.form.resetValidation()
            })
        })
      }
    }
  },
  created () {
    console.log(this.$store.getters.showNav2)
    this.$store.commit('toggleActive', false)
    if (this.$store.getters.isLoggedIn) {
      this.$router.push('/')
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');
</style>
