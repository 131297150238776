<template>
  <v-sheet>
      <v-container>
        <p class="pl-5 mb-0 primary--text font-weight-black text-h4" style="font-family: Nunito, sans-serif !important">Groups</p>
        <v-card class="mt-4" flat outlined>
            <v-card-title>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="groups"
                :loading="dataLoading"
                :search="search"
                loading-text="Loading... Please wait"
                class="elevation-1"
                hide-default-footer
                disable-pagination
            >
              <template
                  v-slot:item.actions="{ item }"
              >
                  <v-btn color="green" text small @click="editItem(item)"><v-icon small>mdi-pencil</v-icon> Edit</v-btn>
              </template>
            </v-data-table>
        </v-card>
      </v-container>
      <v-dialog
        v-model="editDialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Edit Group</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              >
                  <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                    label="Hotline"
                    type="number"
                    v-model="hotline"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-autocomplete
                        v-model="escalation2"
                        :items="users"
                        label="Escalation Level 2"
                        item-text="Name"
                        item-value="ID"
                        :rules="[v => !!v || 'Required']"
                        return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-autocomplete
                        v-model="escalation3"
                        :items="users"
                        label="Escalation Level 3"
                        item-text="Name"
                        item-value="ID"
                        :rules="[v => !!v || 'Required']"
                        return-object
                    ></v-autocomplete>
                  </v-col> -->
                  </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="editDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="saveItem"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-sheet>
</template>

<script>
export default {
  name: 'Locations',
  props: ['users'],
  data: () => ({
    groups: [],
    dataLoading: true,
    valid: true,
    search: '',
    headers: [
      { text: 'ID', value: 'ID', sortable: false },
      { text: 'Group Name', value: 'GroupName', sortable: false },
      { text: 'Hotline', value: 'HotlineNumber', sortable: false },
      // { text: 'Escalation Level 1', value: 'EscalationLevelOneUser.Name', sortable: false },
      // { text: 'Escalation Level 2', value: 'EscalationLevelTwoUser.Name', sortable: false },
      // { text: 'Escalation Level 3', value: 'EscalationLevelThreeUser.Name', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    modalItem: {},
    editDialog: false,
    escalation1: null,
    escalation2: null,
    escalation3: null,
    hotline: null
  }),
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.$http.get(this.$url + '/api/group').then(response => {
        this.groups = response.data.data
        this.dataLoading = false
      }, (err) => {
        if (err.response.status === 401) {
          this.$store.dispatch('logout')
          this.$router.push('/login')
        }
        throw err
      })
    },
    editItem (item) {
      this.modalItem = Object.assign(item, {})
      // this.escalation1 = this.modalItem.EscalationLevelOneUser
      // this.escalation2 = this.modalItem.EscalationLevelTwoUser
      // this.escalation3 = this.modalItem.EscalationLevelThreeUser
      this.hotline = parseInt(this.modalItem.HotlineNumber)
      this.editDialog = true
    },
    saveItem () {
      if (this.$refs.form.validate()) {
        const postObj = Object.assign(this.modalItem, {})
        // postObj.EscalationLevelOneUserID = this.escalation1.ID
        // postObj.EscalationLevelOneUser = this.escalation1
        // postObj.EscalationLevelTwoUserID = this.escalation2.ID
        // postObj.EscalationLevelTwoUser = this.escalation2
        // postObj.EscalationLevelThreeUserID = this.escalation3.ID
        // postObj.EscalationLevelThreeUser = this.escalation3
        postObj.HotlineNumber = this.hotline
        this.$http.post(this.$url + '/api/group', postObj).then(response => {
          this.getData()
          this.editDialog = false
          this.$refs.form.reset()
        })
      }
    }
  }
}
</script>
