<template>
    <v-container fluid>
        <p class="pl-5 mb-0 primary--text font-weight-black text-h4" style="font-family: Nunito, sans-serif !important">New Ticket</p>
        <v-divider class="mt-3"></v-divider>
        <v-row justify="center" class="mt-2">
            <v-col cols="12" sm="10" md="8">
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                >
                    <!-- <v-checkbox
                    v-model="serviceRequest"
                    label="Service Request"
                    v-if="currentUser.GroupID === 1"
                    ></v-checkbox> -->
                    <v-autocomplete
                        v-if="currentUser.GroupID === 1"
                        v-model="requestor"
                        :items="users"
                        dense
                        outlined
                        label="Requestor"
                        item-text="Name"
                        item-value="ID"
                        :rules="[v => !!v || 'Required']"
                        return-object
                    ></v-autocomplete>
                    <v-autocomplete
                        v-model="location"
                        :items="locations"
                        dense
                        outlined
                        label="Location"
                        item-text="LocationName"
                        :rules="[v => !!v || 'Required']"
                        item-value="ID"
                    ></v-autocomplete>
                    <v-combobox
                    v-model="taskType"
                    :items="tickettypes"
                    label="Tickets"
                    item-text="TaskTypeName"
                    item-value="TaskTypeName"
                    :rules="[v => !!v || 'Required']"
                    multiple
                    dense
                    outlined
                    chips
                    >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        label
                        small
                        >
                        <span class="pr-2">
                            {{ item.TaskTypeDetail }}
                        </span>
                        <v-icon
                            small
                            @click="parent.selectItem(item)"
                        >
                            mdi-close
                        </v-icon>
                        </v-chip>
                    </template>
                    </v-combobox>
                    <!-- <v-radio-group
                    v-model="requestBy"
                    row
                    dense
                    >
                    <v-radio
                        label="Crew"
                        value="Crew"
                    ></v-radio>
                    <v-radio
                        label="Guest"
                        value="Guest"
                    ></v-radio>
                    </v-radio-group> -->
                    <p class="font-weight-bold mb-0">Priority</p>
                    <v-radio-group
                    v-model="priority"
                    row
                    dense
                    >
                    <v-radio
                        label="Low"
                        value="Low"
                    ></v-radio>
                    <v-radio
                        label="Medium"
                        value="Medium"
                    ></v-radio>
                    <v-radio
                        label="High"
                        value="High"
                    ></v-radio>
                    </v-radio-group>
                    <v-textarea
                    outlined
                    v-model="details"
                    :rules="[v => !!v || 'Required']"
                    label="Details"
                    ></v-textarea>
                </v-form>
                <div>
                    <v-btn @click="submitTask" class="primary">Submit <v-icon class="ml-2" small>mdi-send</v-icon></v-btn>
                </div>
            </v-col>
        </v-row>
        <v-snackbar
        :timeout="2000"
        :value="snackBar"
        :color="snackColor"
        absolute
        right
        rounded="pill"
        top
      >
        {{snacktext}}
      </v-snackbar>
    </v-container>
</template>
<script>
export default {
  props: ['users', 'currentUser'],
  data: () => ({
    valid: true,
    serviceRequest: false,
    locations: [],
    tickettypes: [],
    priority: 'Low',
    location: null,
    requestor: null,
    taskType: null,
    requestBy: 'Crew',
    details: null,
    snackBar: false,
    snackColor: '',
    snacktext: ''
  }),
  mounted () {
    this.requestor = this.currentUser
    this.$http.get(this.$url + '/api/location').then((response) => {
      this.locations = response.data.data.filter(item => {
        if (this.currentUser.GroupID !== 1) {
          return item.LocationGroupID === this.currentUser.GroupID
        } else {
          return item
        }
      })
    })
    this.$http.get(this.$url + '/api/tasktype').then((response) => {
      this.tickettypes = response.data.data.filter((item) => item.TaskTypeDetail !== '')
    })
  },
  methods: {
    submitTask () {
      if (this.$refs.form.validate()) {
        for (const type of this.taskType) {
          const postObj = {}
          // postObj.AttentionUsers = null
          // postObj.CreatedAt = "2017-12-24T14:56:11+05:00"
          // postObj.ID = 1
          // postObj.Location = {ID: 0, LocationName: "", LocationNumber: "", LocationType: ""}
          postObj.LocationID = this.location
          // postObj.RequestType = this.serviceRequest ? 'Service' : this.requestBy
          // postObj.TaskAssignedGroup = {ID: 1, GroupName: "IT Helpdesk", HotlineNumber: "9607905569", EscalationLevelOneUserID: 1,…}
          // postObj.TaskAssignedGroupID = 1
          // postObj.TaskAssignedUser = {ID: 19, Username: "migdhaam", Name: "Migdhaam Mohammed Ibrahim", EmployeeNumber: "182",…}
          // postObj.TaskAssignedUserID = 19
          postObj.TaskDetails = this.details
          // postObj.TaskDueDate = "2017-12-24T15:11:11+05:00"
          // postObj.TaskEscalatedLevel = 0
          // postObj.TaskHistory = null
          postObj.TaskStatus = 'NEW'
          postObj.TaskType = type
          postObj.TaskTypeID = type.ID
          // postObj.TaskUpdatedUser = {ID: 11, Username: "fahmee", Name: "ABDULLA FAHMEE", EmployeeNumber: "343", MobileNumber: "9607929565",…}
          // if (this.serviceRequest) {
          //   postObj.TaskUpdatedUserID = this.currentUser.ID
          // }
          postObj.TaskUpdatedUserID = this.requestor ? this.requestor.ID : this.currentUser.ID

          this.$http.post(this.$url + '/api/task', postObj).then(response => {
            this.snackBar = true
            this.snackColor = 'success'
            this.snacktext = 'New Task Posted'
            this.$refs.form.reset()
          }, () => {
            this.snackBar = true
            this.snackColor = 'error'
            this.snacktext = 'Error Occured. Call IT.'
          })
        }
      }
    }
  }
}
</script>
