<template>
  <v-sheet>
      <v-container>
        <p class="pl-5 mb-0 primary--text font-weight-black text-h4" style="font-family: Nunito, sans-serif !important">Task Types</p>
        <div class="text-right">
            <v-btn color="primary" @click="showDialog = true"><v-icon>mdi-plus</v-icon> Add Task Type</v-btn>
        </div>
        <v-card class="mt-4" flat outlined>
            <v-card-title>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="taskTypes"
                :loading="dataLoading"
                :search="search"
                loading-text="Loading... Please wait"
                class="elevation-1"
                hide-default-footer
                disable-pagination
            ></v-data-table>
        </v-card>
      </v-container>
      <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Task Type</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            >
                <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                    <!-- <v-select
                    :items="['Defect', 'Request']"
                    label="Task Type Name"
                    :rules="[v => !!v || 'Required']"
                    v-model="TaskTypeName"
                    ></v-select> -->
                    <v-text-field
                    label="Type Name"
                    :rules="[v => !!v || 'Required']"
                    v-model="TaskTypeName"
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                    <v-text-field
                    label="Type Details"
                    :rules="[v => !!v || 'Required']"
                    v-model="TaskTypeDetail"
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                    <!-- <v-select
                    :items="uniqTypes"
                    label="Location Type"
                    :rules="[v => !!v || 'Required']"
                    v-model="GroupName"
                    ></v-select> -->
                    <!-- <v-autocomplete
                        v-model="GroupName"
                        :items="groups"
                        label="Group"
                        item-text="GroupName"
                        item-value="ID"
                        :rules="[v => !!v || 'Required']"
                    ></v-autocomplete> -->
                </v-col>
                </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="showDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="submitTaskType"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="2000"
      :value="snackBar"
      :color="snackColor"
      absolute
      right
      rounded="pill"
      top
    >
      {{snacktext}}
    </v-snackbar>
  </v-sheet>
</template>

<script>
// import { _ } from 'vue-underscore'
export default {
  name: 'TaskTypes',
  data: () => ({
    taskTypes: [],
    dataLoading: true,
    valid: true,
    search: '',
    headers: [
      { text: 'ID', value: 'ID', sortable: false },
      { text: 'Name', value: 'TaskTypeName', sortable: false },
      { text: 'Detail', value: 'TaskTypeDetail', sortable: false },
      { text: 'Group', value: 'TaskTypeGroup.GroupName', sortable: false }
    ],
    showDialog: false,
    TaskTypeName: null,
    TaskTypeDetail: null,
    GroupName: null,
    groups: [],
    snackBar: false,
    snackColor: '',
    snacktext: ''
  }),
  created () {
    this.getTypes()
    this.$http.get(this.$url + '/api/group').then(response => {
      this.groups = response.data.data
    }, (err) => {
      if (err.response.status === 401) {
        this.$store.dispatch('logout')
        this.$router.push('/login')
      }
      throw err
    })
  },
  methods: {
    getTypes () {
      this.$http.get(this.$url + '/api/tasktype').then((response) => {
        this.dataLoading = false
        this.taskTypes = response.data.data.filter((item) => item.TaskTypeDetail !== '')
      })
    },
    submitTaskType () {
      if (this.$refs.form.validate()) {
        const postObj = {}
        postObj.TaskTypeName = this.TaskTypeName
        postObj.TaskTypeDetail = this.TaskTypeDetail
        // postObj.TaskTypeGroupID = this.GroupName
        this.$http.post(this.$url + '/api/tasktype', postObj).then((response) => {
          this.showDialog = false
          this.snackBar = true
          this.snackColor = 'success'
          this.snacktext = 'New Type Posted'
          this.$refs.form.reset()
          this.getTypes()
        }, (err) => {
          if (err.response.status === 401) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
          } else {
            this.snackBar = true
            this.snackColor = 'error'
            this.snacktext = 'Error Occured. Call IT.'
          }
        })
      }
    }
  }
}
</script>
