<template>
  <v-container fluid>
        <p class="pl-5 mb-0 primary--text font-weight-black text-h4" style="font-family: Nunito, sans-serif !important">TICKET #{{$route.params.id}}</p>
        <!-- <v-divider class="mt-3"></v-divider> -->
        <v-card class="mt-3" outlined flat>
            <v-card-text>
                <v-row justify="space-between">
                    <v-col>
                        <v-chip
                        class="ma-2"
                        color="primary"
                        outlined
                        pill
                        >
                        <v-icon left>
                            mdi-account-outline
                        </v-icon>
                        {{request.TaskUpdatedUser ? request.TaskUpdatedUser.Name : ''}}
                        </v-chip>
                        <v-chip
                        class="ma-2"
                        :color="request.TaskStatus === 'Pending' ? 'warning' : request.TaskStatus === 'Reopen' ? 'info': 'success'"
                        label
                        text-color="white"
                        >
                        <v-icon left>
                            mdi-label
                        </v-icon>
                        {{request.TaskStatus}}
                        </v-chip>
                    </v-col>
                    <v-col class="text-right" v-if="request.TaskStatus !== 'Complete'">
                        <v-tooltip bottom v-if="currentUser.GroupID === 1">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            @click="openDueModal()"
                            >
                            <v-icon dark>
                                mdi-clock
                            </v-icon>
                            </v-btn>
                        </template>
                        <span>Add Time</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="currentUser.GroupID === 1">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="info"
                            v-bind="attrs"
                            v-on="on"
                            @click="assignUserDialog = true"
                            >
                            <v-icon dark>
                                mdi-account-star-outline
                            </v-icon>
                            </v-btn>
                        </template>
                        <span>Assign To</span>
                        </v-tooltip>
                        <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="warning"
                            v-bind="attrs"
                            v-on="on"
                            @click="assignDeptDialog = true"
                            >
                            <v-icon dark>
                                mdi-family-tree
                            </v-icon>
                            </v-btn>
                        </template>
                        <span>Send To</span>
                        </v-tooltip> -->
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                >
                                Chane Status <v-icon>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="changeStatus('Complete')">
                                    <v-list-item-title>Complete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            </v-menu>
                    </v-col>
                    <v-col class="text-right" v-else>
                        <v-btn
                        color="warning"
                        dark
                        @click="reopenModal = true"
                        >
                        Reopen Ticket
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <v-row>
                    <v-col sm="3">
                        <span class="font-weight-bold mr-3">Location:</span>{{request.Location ? request.Location.LocationName : ''}}
                    </v-col>
                    <v-col sm="3"><span class="font-weight-bold mr-3">Type:</span>{{request.RequestType}}
                        </v-col>
                    <v-col sm="3"><span class="font-weight-bold mr-3">Ticket Type:</span>{{request.TaskType ? request.TaskType.TaskTypeDetail : ''}}
                        </v-col>
                    <v-col sm="3"><span class="font-weight-bold mr-3 error--text">Due Date:</span>{{ new Date(request.TaskDueDate) | date('dd MMM yyyy, HH:mm')}}
                        </v-col>
                    <v-col sm="3"><span class="font-weight-bold mr-3">Assigned To:</span>{{request.TaskAssignedUser.Name}}
                        </v-col>
                    <!-- <v-col sm="3"><span class="font-weight-bold mr-3">Assigned Group:</span>{{request.TaskAssignedGroup.GroupName}}
                        </v-col> -->
                    <v-col sm="6"><span class="font-weight-bold mr-3">Detail:</span>{{request.TaskDetails}}
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <p class="text-h6 mt-5">Comments</p>
        <v-card style="min-height: 300px" flat>
            <v-card-text  v-if="request.TaskHistory.length > 0">
                <v-card class="mb-3" :rounded="true" style="border-radius: 23px !important; min-width: 200px; max-width: fit-content;" flat outlined color="cyan lighten-5" v-for="(value, key) in request.TaskHistory" :key="key">
                    <v-card-text>
                        <p class="mb-1">{{value.TaskHistoryDetail}}</p>
                        <p class="text-right mb-0 text-caption text--disabled">{{value.User ? value.User.Name + ' -' : ''}} {{new Date(value.CreatedAt) | date('dd MMM yyyy, HH:mm')}}</p>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-text>
                <v-row>
                    <v-col sm="6">
                        <v-textarea
                        rows="0"
                        outlined
                        label
                        v-model="comment"
                        ></v-textarea>
                        <!-- <v-combobox
                        v-model="attentionUser"
                        :items="users"
                        label="Bring Attention To (optional)"
                        item-text="Name"
                        item-value="ID"
                        multiple
                        outlined
                        chips
                        dense
                        >
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                            <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            label
                            small
                            >
                            <span class="pr-2">
                                {{ item.Name }}
                            </span>
                            <v-icon
                                small
                                @click="parent.selectItem(item)"
                            >
                                mdi-close
                            </v-icon>
                            </v-chip>
                        </template>
                        </v-combobox> -->
                        <v-btn
                        color="primary"
                        @click="sendComments"
                        >
                        Comment
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog
        v-model="assignUserDialog"
        persistent
        max-width="600px"
        >
        <v-card>
            <v-card-title>
            <span class="headline">Assign User</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                >
                    <v-row>
                    <v-col
                        cols="12"
                    >
                        <v-autocomplete
                            v-model="assignUser"
                            :items="users"
                            dense
                            outlined
                            label="Select a User"
                            item-text="Name"
                            item-value="ID"
                            :rules="[v => !!v || 'Required']"
                            return-object
                        ></v-autocomplete>
                    </v-col>
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="assignUserDialog = false"
            >
                Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="saveAssignUser"
            >
                Save
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
        v-model="assignDeptDialog"
        persistent
        max-width="600px"
        >
        <v-card>
            <v-card-title>
            <span class="headline">Assign Group</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-form
                ref="form2"
                v-model="valid"
                lazy-validation
                >
                    <v-row>
                    <v-col
                        cols="12"
                    >
                        <v-autocomplete
                            v-model="assignDept"
                            :items="groups"
                            dense
                            outlined
                            label="Select a Group"
                            item-text="GroupName"
                            item-value="ID"
                            :rules="[v => !!v || 'Required']"
                            return-object
                        ></v-autocomplete>
                    </v-col>
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="assignDeptDialog = false"
            >
                Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="saveAssignDept"
            >
                Save
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
        v-model="dueDateDialog"
        persistent
        max-width="600px"
        >
        <v-card>
            <v-card-title>
            <span class="headline">Change Due Date</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-tabs v-model="tabs">
                    <v-tab>Add Minutes</v-tab>
                    <v-tab>Pick a Date&Time</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs">
                    <v-tab-item>
                        <v-card flat outlined>
                            <v-card-text>
                                <v-form
                                ref="form3"
                                v-model="valid"
                                lazy-validation
                                >
                                    <v-select
                                    label="Add"
                                    v-model="selectedMinutes"
                                    :items="['10 Minutes', '15 Minutes', '20 Minutes', '30 Minutes', '40 Minutes', '45 Minutes', '50 Minutes']"
                                    ></v-select>
                                    <v-textarea
                                    label="Reason"
                                    rows="2"
                                    v-model="dueReason"></v-textarea>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat outlined>
                            <v-card-text>
                                <v-form
                                ref="form3"
                                v-model="valid"
                                lazy-validation
                                >
                                    <v-row>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <v-menu
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="date"
                                                label="Pick a date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="date"
                                            no-title
                                            @input="menu = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <v-menu
                                            ref="menu"
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="time"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="time"
                                                label="Pick a time"
                                                prepend-icon="mdi-clock-time-four-outline"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-time-picker
                                            v-if="menu2"
                                            v-model="time"
                                            full-width
                                            format="24hr"
                                            @click:minute="$refs.menu.save(time)"
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                    </v-row>
                                    <v-textarea
                                    label="Reason"
                                    rows="2"
                                    v-model="dueReason"
                                    ></v-textarea>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dueDateDialog = false"
            >
                Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="saveDueDate"
            >
                Save
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
        v-model="reopenModal"
        persistent
        max-width="600px"
        >
        <v-card>
            <v-card-title>
            <span class="headline">Reopen Ticket</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-form
                ref="form4"
                v-model="valid"
                lazy-validation
                >
                    <v-row>
                    <v-col
                        cols="12"
                    >
                        <v-textarea
                        label="Reason"
                        :rules="[v => !!v || 'Required']"
                        v-model="reopenReason"
                        ></v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="reopenModal = false"
            >
                Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="reopenTask"
            >
                Save
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import { addMinutes } from 'date-fns/addMinutes'
export default {
  props: ['users', 'currentUser'],
  data: () => ({
    menu: false,
    menu2: false,
    tasktype: '',
    request: {},
    date: null,
    time: null,
    comment: null,
    attentionUser: null,
    assignUserDialog: false,
    assignDeptDialog: false,
    dueDateDialog: false,
    assignUser: null,
    assignDept: null,
    valid: true,
    groups: [],
    reopenModal: false,
    reopenReason: null,
    tabs: null,
    selectedMinutes: '15 Minutes',
    dueReason: null
  }),
  created () {
    this.$http.get(this.$url + '/api/group').then(response => {
      this.groups = response.data.data
    })
    this.getData()
  },
  methods: {
    getData () {
      this.$http.get(this.$url + `/api/task/${this.$route.params.id}`).then(response => {
        this.request = response.data.data
      }, (err) => {
        if (err.response.status === 401) {
          this.$store.dispatch('logout')
          this.$router.push('/login')
        }
      })
    },
    openDueModal () {
      const date = Object.assign(this.request, {})
      const d = new Date(date.TaskDueDate)
      d.setMinutes(d.getMinutes() + 15)
      this.date = this.$date(d, 'yyyy-MM-dd')
      this.time = this.$date(d, 'HH:mm')
      this.dueDateDialog = true
    },
    sendComments () {
      if (this.comment && this.comment.length > 0) {
        const postobj = {}
        postobj.TaskHistoryDetail = this.comment
        this.$http.post(this.$url + `/api/task/${this.$route.params.id}/comment`, postobj).then(response => {
          this.comment = ''
          this.getData()
        }, (err) => {
          if (err.response.status === 401) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
          }
        })
      }
    },
    sendLogs (val) {
      const postobj = {}
      postobj.TaskHistoryDetail = val
      this.$http.post(this.$url + `/api/task/${this.$route.params.id}/comment`, postobj).then(response => {
        this.getData()
      }, (err) => {
        if (err.response.status === 401) {
          this.$store.dispatch('logout')
          this.$router.push('/login')
        }
      })
    },
    saveAssignUser () {
      if (this.$refs.form.validate()) {
        // const postObj = Object.assign(this.request, {})
        // postObj.TaskAssignedUser = this.assignUser
        // postObj.TaskAssignedUserID = this.assignUser.ID
        this.$http.post(this.$url + `/api/task/${this.$route.params.id}/assignuser`, this.assignUser).then(response => {
          this.assignUserDialog = false
          this.getData()
        }, (err) => {
          if (err.response.status === 401) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
          }
        })
      }
    },
    saveAssignDept () {
      if (this.$refs.form2.validate()) {
        // const postObj = Object.assign(this.request, {})
        // postObj.TaskAssignedGroup = this.assignDept
        // postObj.TaskAssignedGroupID = this.assignUser.ID
        this.$http.post(this.$url + `/api/task/${this.$route.params.id}/send`, this.assignDept).then(response => {
          this.assignDeptDialog = false
          this.getData()
        }, (err) => {
          if (err.response.status === 401) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
          }
        })
      }
    },
    saveDueDate () {
      const postObj = Object.assign(this.request, {})
      const duetime = postObj.TaskDueDate
      if (this.$refs.form3.validate()) {
        if (this.tabs === 0) {
          var d = new Date(this.request.TaskDueDate)
          d.setMinutes(d.getMinutes() + parseInt(this.selectedMinutes.substring(0, 2)))
          postObj.TaskDueDate = d
          this.$http.post(this.$url + '/api/task', postObj).then(response => {
            this.sendLogs('Duedate changed from ' + this.$date(new Date(duetime), 'dd MMMM yyyy HH:mm') + ' to ' + this.$date(d, 'dd MMMM yyyy HH:mm') + '. Reason: ' + this.dueReason)
            this.dueDateDialog = false
            this.getData()
            this.$refs.form3.reset()
          }, (err) => {
            if (err.response.status === 401) {
              this.$store.dispatch('logout')
              this.$router.push('/login')
            }
          })
        } else {
          postObj.TaskDueDate = new Date(this.date + 'T' + this.time + ':00+05:00')
          this.$http.post(this.$url + '/api/task', postObj).then(response => {
            this.sendLogs('Duedate changed from ' + this.$date(new Date(duetime), 'dd MMMM yyyy HH:mm') + ' to ' + this.date + ' ' + this.time + '. Reason: ' + this.dueReason)
            this.dueDateDialog = false
            this.getData()
            this.$refs.form3.reset()
          }, (err) => {
            if (err.response.status === 401) {
              this.$store.dispatch('logout')
              this.$router.push('/login')
            }
          })
        }
      }
    },
    changeStatus (status) {
      const postObj = Object.assign(this.request, {})
      const statusDefault = this.request.TaskStatus
      postObj.TaskStatus = status
      this.$http.post(this.$url + '/api/task', postObj).then(response => {
        this.sendLogs('Status changed from ' + statusDefault + ' to ' + status)
        this.dueDateDialog = false
        this.getData()
      }, (err) => {
        if (err.response.status === 401) {
          this.$store.dispatch('logout')
          this.$router.push('/login')
        }
      })
    },
    reopenTask () {
      if (this.$refs.form4.validate()) {
        const postObj = Object.assign(this.request, {})
        postObj.TaskStatus = 'Reopen'
        this.$http.post(this.$url + '/api/task', postObj).then(response => {
          this.sendLogs('Ticket reopened. Reason: ' + this.reopenReason)
          this.reopenModal = false
          this.getData()
        }, (err) => {
          if (err.response.status === 401) {
            this.$store.dispatch('logout')
            this.$router.push('/login')
          }
        })
      }
    }
  }
}
</script>
