<template>
  <v-sheet>
      <v-container>
        <p class="pl-5 mb-0 primary--text font-weight-black text-h4" style="font-family: Nunito, sans-serif !important">Locations</p>
        <div class="text-right">
            <v-btn color="primary" v-if="currentUser.IsAdmin" @click="showDialog = true"><v-icon>mdi-plus</v-icon> Add Location</v-btn>
        </div>
        <v-card class="mt-4" flat outlined>
            <v-card-title>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="locations"
                :loading="dataLoading"
                :search="search"
                loading-text="Loading... Please wait"
                class="elevation-1"
                hide-default-footer
                disable-pagination
            ></v-data-table>
        </v-card>
      </v-container>
      <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Location</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            >
                <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                    <v-text-field
                    label="Location Name"
                    :rules="[v => !!v || 'Required']"
                    v-model="locationName"
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                    <v-text-field
                    label="Location Address"
                    :rules="[v => !!v || 'Required']"
                    v-model="locationAddress"
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                    <v-select
                    :items="uniqTypes"
                    label="Location Group"
                    :rules="[v => !!v || 'Required']"
                    v-model="locationType"
                    item-text="GroupName"
                    item-value="ID"
                    ></v-select>
                </v-col>
                </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="showDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="submitLocation"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { _ } from 'vue-underscore'
export default {
  name: 'Locations',
  props: ['currentUser'],
  data: () => ({
    locations: [],
    dataLoading: true,
    valid: true,
    search: '',
    headers: [
      { text: 'Name', value: 'LocationName', sortable: false },
      { text: 'Address', value: 'LocationAddress', sortable: false },
      { text: 'Group', value: 'LocationGroup.GroupName', sortable: false },
      { text: 'Hotline', value: 'LocationGroup.HotlineNumber', sortable: false }
    ],
    showDialog: false,
    locationName: null,
    locationAddress: null,
    locationType: null,
    uniqTypes: []
  }),
  created () {
    this.getLocations()
  },
  methods: {
    getLocations () {
      this.$http.get(this.$url + '/api/location').then(response => {
        this.locations = response.data.data
        const groupObjs = _.pluck(this.locations, 'LocationGroup')
        // const groups = _.map(groupObjs, function (item) { return _.omit(item, 'ID') })
        // console.log(_.uniq(groups, 'GroupName'))
        // const groupNames = _.pluck(groupObjs, 'GroupName')
        // console.log(groupNames)
        this.uniqTypes = _.uniq(groupObjs, 'GroupName')
        this.uniqTypes = _.sortBy(this.uniqTypes, function (num) { return num ? num.GroupName.toLowerCase().substring(0, 1) : '' })
        this.dataLoading = false
      })
    },
    submitLocation () {
      if (this.$refs.form.validate()) {
        const postObj = {}
        postObj.LocationName = this.locationName
        postObj.LocationGroupID = parseInt(this.locationType)
        postObj.LocationAddress = this.locationAddress
        this.$http.post(this.$url + '/api/location', postObj).then(response => {
          this.getLocations()
          this.showDialog = false
          this.$refs.form.reset()
        })
      }
    }
  }
}
</script>
