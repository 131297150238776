<template>
<div>
    <v-container>
        <p class="pl-5 primary--text font-weight-black text-h4" style="font-family: Nunito, sans-serif !important">{{$route.params.type}}</p>
        <!-- <v-row>
          <v-col cols="6" sm="2" md="1">
            <p class="font-weight-bold mt-sm-2">Filter By: </p>
          </v-col>
          <v-col cols="6" sm="3">
            <v-select
            :items="['None', 'Status']"
            v-model="filterBy"
            outlined
            dense
            ></v-select>
          </v-col>
        </v-row> -->
        <v-card>
          <!-- <v-card-title>Filter:</v-card-title> -->
          <v-card-text>
            <v-row>
              <v-col sm="3">
                <v-select
                :items="['All', 'Pending', 'Complete', 'Reopen']"
                label="Filter By Status"
                outlined
                dense
                hide-details
                v-model="filterStatus"
                @change="filterList('status')"
                ></v-select>
              </v-col>
              <v-col sm="3">
                <v-text-field
                label="Filter By Task Code"
                outlined
                dense
                v-model="filterCode"
                @input="filterList('code')"
                hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider class="mt-3"></v-divider>
        <!-- <v-divider class="mt-3"></v-divider> -->
        <v-data-table
            :headers="headers"
            :items="requests"
            :items-per-page="50"
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            class="elevation-1"
            hide-default-footer
            @click:row="taskDetails"
        >

            <template
                v-slot:item.CreatedAt="{ item }"
            >
              <div style="width: 170px">
                  <v-icon class="mr-1" :color="item.TaskEscalatedLevel == 0 ? 'teal accent-4' : item.TaskEscalatedLevel == 1 ? 'grey lighten-1' : item.TaskEscalatedLevel == 2 ? 'yellow accent-4' : 'red darken-4'">mdi-label</v-icon>
                  {{ new Date(item.CreatedAt) | date('dd MMM yyyy, HH:mm') }}
              </div>
            </template>
            <!-- <template
                v-slot:item.CreatedAt="{ item }"
            >
                {{ new Date(item.CreatedAt) | date('dd MMM yyyy, HH:mm') }}
            </template> -->
            <template
                v-slot:item.TaskDueDate="{ item }"
            >
                {{ new Date(item.TaskDueDate) | date('dd MMM yyyy HH:mm') }}
            </template>
            <template
                v-slot:item.TaskStatus="{ item }"
            >
                <span>
                    <v-chip dark :color="item.TaskStatus === 'Pending' ? 'orange lighten-2' : item.TaskStatus === 'Complete' ? 'green lighten-1' : 'info'">{{item.TaskStatus}}</v-chip>
                </span>
            </template>
            <!-- <template
                v-slot:item.ticketUserId="{ item }"
            >
                {{getUserName(item.ticketUserId)}}
            </template> -->
        </v-data-table>
        <div class="text-center my-5">
          <v-pagination
            v-model="page"
            :length="numberOfItems"
            :total-visible="7"
            circle
            v-if="numberOfItems > 1"
          ></v-pagination>
        </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    headers: [
      { text: 'Created At', value: 'CreatedAt', sortable: false },
      { text: 'Ticket Number', value: 'ID', sortable: false },
      { text: 'Location', value: 'Location.LocationName', sortable: false },
      { text: 'Created By', value: 'TaskUpdatedUser.Name', sortable: false },
      // { text: 'Group', value: 'TaskAssignedGroup.GroupName', sortable: false },
      { text: 'Assigned To', value: 'TaskAssignedUser.Name', sortable: false },
      { text: 'Due Date', value: 'TaskDueDate', sortable: false },
      { text: 'Ticket Type', value: 'TaskType.TaskTypeDetail', sortable: false },
      { text: 'Ticket Details', value: 'TaskDetails', sortable: false },
      { text: 'Status', value: 'TaskStatus', sortable: false }
    ],
    requests: [],
    dataLoading: true,
    page: 1,
    numberOfItems: null,
    currentPath: '',
    filterBy: 'None',
    filterStatus: null,
    filterCode: null
  }),
  created () {
    this.currentPath = this.$route.params.type
    this.getItems(this.$route.params.type)
  },
  watch: {
    '$route.params.type': function (val) {
      this.currentPath = val
      this.getItems(val)
    },
    page: function (val) {
      this.getItems(this.currentPath)
    }
  },
  methods: {
    getUserName (id) {
      return id
    },
    taskDetails (item) {
      this.$router.push('/singleticket/' + item.ID)
    },
    getItems (path, filterText) {
      this.dataLoading = true
      this.requests = []
      let url = ''
      if (path === 'All Tickets') {
        url = this.$url + `/api/task?limit=50&page=0&page=${this.page - 1}&${filterText}&sort=-created_at`
      } else {
        url = this.$url + `/api/tasks/${path === 'My Requests' ? 'byme' : path === 'Assigned To Me' ? 'tome' : path === 'To Department' ? 'todept' : 'fromdept'}?limit=50&${filterText}&page=${this.page - 1}&sort=-created_at `
      }
      this.$http.get(url).then(response => {
        this.requests = response.data.data.rows
        this.numberOfItems = parseInt(response.data.data.total_rows / 50)
        this.dataLoading = false
      }, (err) => {
        if (err.response.status === 401) {
          this.$store.dispatch('logout')
          this.$router.push('/login')
        }
        throw err
      })
    },
    filterList (type) {
      var filterText = ''
      if (type === 'status') {
        filterText = `task_status.contains=${this.filterStatus === 'All' ? '' : this.filterStatus}`
      }
      if (type === 'code') {
        filterText = `id.contains=${this.filterCode}`
      }
      this.getItems(this.currentPath, filterText)
    }
  }
}
</script>
